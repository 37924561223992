import styled, { createGlobalStyle } from 'styled-components'
import { Button } from 'reactstrap'

export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  }
  #demo {
    height: 100%;
  }
`
export const EmailEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`
export const SpinnerContainer = styled.div`
  padding-top: 300px;
`
export const FileManagerButton = styled(Button)`
  margin-top: 0.8em;
  margin-right: 0.5em;
`
export const FileManagerImg = styled.img`
  max-width: 7em;
  max-height: 3em;
`
export const FileManagerSpan = styled.span`
  overflow-wrap: break-word;
  font-size: 0.8em;
  font-weight: 400;
`
export const FileManagerTableRow = styled.tr`
  cursor: pointer;
`
export const FileManagerThead = styled.thead`
  font-size: 0.9em;
`
export const HeaderButton = styled(Button)`
  margin: 10px 10px 10px 0;
`
