import * as dotenv from 'dotenv'
dotenv.config()

export const ENV = process.env.REACT_APP_NODE_ENV || 'development';
let base_url
switch (ENV) {
  case 'production':base_url = 'https://portal.thenexus.net'; break;
  case 'staging': base_url = 'https://staging.thenexus.net'; break;
  default: base_url = 'http://localhost:3000'; break;
}
export const BASE_URL = base_url

export const postData = (data) => {
  const defaultRequestOptions = {
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
  }
  return { ...defaultRequestOptions, body: JSON.stringify(data) }
}
export const getData = {
  credentials: 'include',
  mode: 'cors',
  method: 'GET',
}
export const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response
}
export const s3_config = {
  bucketName: 'exposync-uploads',
  accessKeyId: 'AKIAJ7QRP6P4IGPOIPJA',
  secretAccessKey: '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',
  region: 'us-east-1',
  s3Url: ' https://s3.amazonaws.com/exposync-uploads', /* optional */
}
