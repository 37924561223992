import React, { useState, useEffect } from 'react'
import EmailEditor from '../components/email_editor/EmailEditor'
import { BASE_URL, getData, handleErrors, postData } from '../../config'
import { useParams } from 'react-router-dom'
import { SpinnerContainer } from '../components/common/StyledComponents'
import Spinner from '../components/common/Spinner'
import { ToastContainer, toast } from 'react-toastify';

export default () => {
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [template, setTemplate] = useState()
  const [blocks, setBlocks] = useState([])
  const [clients, setClients] = useState([])
  const [error, setError] = useState()
  const [name, setName] = useState('New Template')
  const [savedBlocks, setSavedBlocks] = useState([])
  const [selectedSaveBlock, setSelectedSaveBlock] = useState({})
  const [showSaveBlockModal, setShowSaveBlockModal] = useState(false)
  const [showFileManager, setShowFileManager] = useState(false)
  const [selectedRadio, setSelectedRadio] = useState('expo')
  const [selectedClient, setSelectedClient] = useState()

  const sortBlocks = (a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    else return -1
  }

  useEffect(() => {
    const fetchClients = async () => {
      await fetch(`${BASE_URL}/api/companies/expo/${params.eid}?q=&ps=1000&status=client&company_type=exhibitor_company`, getData)
        .then(handleErrors)
        .then(response => response.json())
        .then(ret => {
          setClients(ret.data)
        })
        .catch(setError)
    }
    const fetchSavedBlocks = async () => {
      await fetch(`${BASE_URL}/api/email_editor/blocks/${params.eid}`, getData)
        .then(handleErrors)
        .then(response => response.json())
        .then(ret => {
          setBlocks(ret.sort(sortBlocks))
        })
        .catch(setError)
    }
    const fetchTemplate = async () => {
      await fetch(`${BASE_URL}/api/templates/${params.id}`, getData)
        .then(handleErrors)
        .then(response => response.json())
        .then(message_template => {
          setTemplate(message_template)
        })
        .catch(setError)
    }
    const fetchCopyFromTemplate = async () => {
      await fetch(`${BASE_URL}/api/templates/${params.copyFromId}`, getData)
        .then(handleErrors)
        .then(response => response.json())
        .then(message_template => {
          delete message_template.id
          setTemplate(message_template)
        })
        .catch(setError)
    }

    const fetchData = async () => {
      fetchClients()
      fetchSavedBlocks()
      if (params.id) fetchTemplate()
      else if (params.copyFromId) fetchCopyFromTemplate()
      else setLoading(false)
    }

    if (params.eid) fetchData()
    else setError({ message: 'expo_id is required' })
  }, [params])

  useEffect(() => {
    if (template) {
      setName(template.name)
      setSelectedRadio(template.exhibitor_id ? 'client' : 'expo')
      setSelectedClient(template.exhibitor_id)
      setLoading(false)
    }
    setSavedBlocks(blocks.map(block => ({ id: block.id, name: block.name, definition: block.content })))
  }, [template, blocks])

  useEffect(() => { // select first client default when selecting client radio button
    if (!template && selectedRadio === 'client' && !selectedClient) {
      setSelectedClient(clients[0].id)
    }
  }, [selectedRadio, template, selectedClient, clients])

  const toggleFileManager = () => setShowFileManager(!showFileManager)
  const toggleSaveBlockModal = () => setShowSaveBlockModal(!showSaveBlockModal)
  const saveBlock = (name) => {
    if (name) {
      fetch(`${BASE_URL}/api/email_editor/saved_block/${params.eid}`, postData({ content: [selectedSaveBlock.definition], name }))
        .then(response => response.json())
        .then(({ id }) => {
          //TODO: Add save success message
          setSavedBlocks([...savedBlocks, { id, name, definition: [selectedSaveBlock.definition] }].sort(sortBlocks))
          toggleSaveBlockModal()
        })
    }
    else {
      toast.error('Block name is required!', {
        position: "top-right",
      })
    }
  }

  const saveTemplate = (json, html) => {
    const data = {
      name,
      content: [html],
      content_json: json,
      editor_version: 2
    }
    if (selectedClient) data.exhibitor_id = selectedClient
    if (template && template.id) return fetch(`${BASE_URL}/api/templates/${template.id}`, postData(data))
    else return fetch(`${BASE_URL}/api/templates/expo/${params.eid}`, postData(data)).then(response => response.json())
  }

  const onSaveAndClose = (json, html) => {
    if (!name) toast.error('Template name is required!', {
      position: "top-right",
    })
    else saveTemplate(json, html).then(() => window.top.location.href = `${BASE_URL}/services/templates`)
  }

  const onSave = (json, html) => {
    if (!name) toast.error('Template name is required!', {
      position: "top-right",
    })
    else saveTemplate(json, html).then(({ id }) => { if (!template || !template.id) window.top.location.href = `${BASE_URL}/services/templates/edit/?id=${id}&eid=${params.eid}&v=2` })
  }
  const onAutoSave = (json) => {
  }

  const onOpenFileManager = () => {
    toggleFileManager()
  }
  const onTestSend = (email, json, html) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, json, html })
    };
    fetch(`${BASE_URL}/api/email`, requestOptions)
      .then(() => console.log('OK'))
  }
  const onBlockSave = (json) => {
    setSelectedSaveBlock(json)
    setTimeout(() => {
      toggleSaveBlockModal()
    }, 0)
  }
  const onBlockRemove = (id) => {
    if (window.confirm('Are you sure?')) {
      setSavedBlocks(savedBlocks.filter(block => block.id !== id))
      fetch(`${BASE_URL}/api/email_editor/saved_block/${id}`, getData)
    }
  }
  const onBlockEdit = (id) => {
    var name = window.prompt('Block name:', savedBlocks.find(block => block.id === id).name)
    if (name) {
      fetch(`${BASE_URL}/api/email_editor/saved_block/update/${params.eid}/${id}`, postData({ name }))
        .then(() => {
          setSavedBlocks(savedBlocks.map(block => {
            if (block.id === id) block.name = name;
            return block
          }))
          // TODO: add success message
        })
    }
    else toast.error('Block name is required!', {
      position: "top-right",
    })
  }

  const editorParams = {
    template,
    clients,
    eid: params.eid,
    name,
    setName,
    selectedRadio,
    setSelectedRadio,
    selectedClient,
    setSelectedClient,
    savedBlocks,
    showFileManager,
    toggleFileManager,
    showSaveBlockModal,
    toggleSaveBlockModal,
    onSaveAndClose,
    onSave,
    onAutoSave,
    onBlockSave,
    saveBlock,
    onTestSend,
    onOpenFileManager,
    onBlockEdit,
    onBlockRemove
  }

  return error ? <span>{error.message}</span>
    : (loading ? <SpinnerContainer><Spinner /></SpinnerContainer>
      : <>
        <EmailEditor {...editorParams} />
        <ToastContainer />
      </>)
}
