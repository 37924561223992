export default {
  fonts: [
    // List of all font shown in select box
    {
      label: "Andale Mono",
      style: "andale mono,monospace",
    },
    {
      label: "Arial",
      style: "arial,helvetica,sans-serif",
    },
    {
      label: "Arial Black",
      style: "arial black,sans-serif",
    },
    {
      label: "Century Gothic",
      style: "Century Gothic,CenturyGothic,AppleGothic,sans-serif",
    },
    {
      label: "Comic Sans MS",
      style: "comic sans ms,sans-serif",
    },
    {
      label: "Courier New",
      style: "courier new,courier,monospace",
    },
    {
      label: "Georgia",
      style: "georgia,palatino,serif",
    },
    {
      label: "Helvetica",
      style: "helvetica,arial,sans-serif",
    },
    {
      label: "Impact",
      style: "impact,sans-serif",
    },
    {
      label: "K2D",
      style: "K2D",
    },
    {
      label: "Mali",
      style: "Mali",
    },
    {
      label: "Roboto", // Label shown to user
      style: "Roboto,tahoma,sans-serif", // CSS style applied with font selected
    },
    {
      label: "Tahoma",
      style: "tahoma,arial,helvetica,sans-serif",
    },
    {
      label: "Terminal",
      style: "terminal,monaco,monospace",
    },
    {
      label: "Times New Roman",
      style: "times new roman,times,serif",
    },
    {
      label: "Trebuchet MS",
      style: "trebuchet ms,geneva,sans-serif",
    },
    {
      label: "Verdana",
      style: "verdana,geneva,sans-serif",
    },
  ],
  mergeTags: [
    {
      name: "Individual Fields", // Group name
      items: [
        {
          value: "{{{name_prefix}}}", // Text to be inserted
          text: "Prefix", // Shown text in the menu
          label: "Prefix", // Shown description title in the menu
        },
        {
          value: "{{{name_first}}}",
          text: "First Name",
          label: "First Name",
        },
        {
          value: "{{{name_middle}}}",
          text: "Middle Name",
          label: "Middle Name",
        },
        {
          value: "{{{name_last}}}",
          text: "Last Name",
          label: "Last Name",
        },
        {
          value: "{{{name_suffix}}}",
          text: "Suffix",
          label: "Suffix",
        },
        {
          value: "{{{name_salutation}}}",
          text: "Salutation",
          label: "Salutation",
        },
        {
          value: "{{{business_name}}}",
          text: "Business Name",
          label: "Business Name",
        },
        {
          value: "{{{business_title}}}",
          text: "Business Title",
          label: "Business Title",
        },
        {
          value: "{{{business_role}}}",
          text: "Business Role",
          label: "Business Role",
        },
        {
          value: "{{{email_first}}}",
          text: "Email First",
          label: "Email First",
        },
        {
          value: "{{{email_second}}}",
          text: "Email Second",
          label: "Email Second",
        },
        {
          value: "{{{address1_city}}}",
          text: "Address - City",
          label: "Address - City",
        },
        {
          value: "{{{address1_state}}}",
          text: "Address - State",
          label: "Address - State",
        },
        {
          value: "{{{address1_country}}}",
          text: "Address - Country",
          label: "Address - Country",
        },
        {
          value: "{{{hotel_name}}}",
          text: "Hotel",
          label: "Hotel",
        },
        {
          value: "{{{customer_salutation:name_salutation:name_first}}}",
          text: "Salutation - First Name",
          label: "Salutation - First Name",
        },
      ],
    },
    {
      name: "Exhibitor Fields",
      items: [
        {
          value: "{{{exh_exhibitor_id}}}",
          text: "Exhibitor ID",
          label: "Exhibitor ID",
        },
        {
          value: "{{{exh_exhibitor_name}}}",
          text: "Name",
          label: "Name",
        },
        {
          value: "{{{exh_exhibitor_website}}}",
          text: "Website",
          label: "Website",
        },
        {
          value: "{{{exh_exhibitor_description}}}",
          text: "Description",
          label: "Description",
        },
        {
          value: "{{{exh_booth_location}}}",
          text: "Booth Location",
          label: "Booth Location",
        },
        {
          value: "{{{exh_booth_number}}}",
          text: "Booth Number",
          label: "Booth Number",
        },
        {
          value: "{{{exh_logo}}}",
          text: "Logo",
          label: "Logo",
        },
        {
          value: "{{{exh_exh_address_city}}}",
          text: "Address - City",
          label: "Address - City",
        },
        {
          value: "{{{exh_exh_address_state}}}",
          text: "Address - State",
          label: "Address - State",
        },
        {
          value: "{{{exh_exh_address_country}}}",
          text: "Address - Country",
          label: "Address - Country",
        },
        {
          value: "{{{exh_categories}}}",
          text: "Categories",
          label: "Categories",
        },
        {
          value: "{{{exh_guide_name_first}}}",
          text: "Guide First Name",
          label: "Guide First Name",
        },
        {
          value: "{{{exh_guide_name_last}}}",
          text: "Guide Last Name",
          label: "Guide Last Name",
        },
        {
          value: "{{{exh_guide_phone}}}",
          text: "Guide Phone",
          label: "Guide Phone",
        },
        {
          value: "{{{exh_guide_email}}}",
          text: "Guide Email",
          label: "Guide Email",
        },
        {
          value: "{{{exh_facebook_url}}}",
          text: "Facebook Page",
          label: "Facebook Page",
        },
        {
          value: "{{{exh_twitter_url}}}",
          text: "Twitter Page",
          label: "Twitter Page",
        },
        {
          value: "{{{exh_linkedin_url}}}",
          text: "LinkedIn Page",
          label: "LinkedIn Page",
        },
        {
          value: "{{{exh_instagram_url}}}",
          text: "Instagram Page",
          label: "Instagram Page",
        },
        {
          value: "{{{exh_pinterest_url}}}",
          text: "Pinterest Page",
          label: "Pinterest Page",
        },
        {
          value: "{{{exh_snapchat_url}}}",
          text: "Snapchat Page",
          label: "Snapchat Page",
        },
        {
          value: "{{{exh_youtube_url}}}",
          text: "YouTube Page",
          label: "YouTube Page",
        },
      ],
    },
    {
      name: "Expo Fields", // Group name
      items: [
        {
          value: "{{{expo_name}}}",
          text: "Name",
          label: "Name",
        },
        {
          value: "{{{expo_city}}}",
          text: "City",
          label: "City",
        },
        {
          value: "{{{expo_venue}}}",
          text: "Venue",
          label: "Venue",
        },
        {
          value: "{{{start_date}}}",
          text: "Start Date",
          label: "Start Date",
        },
        {
          value: "{{{end_date}}}",
          text: "End Date",
          label: "End Date",
        },
        {
          value: "{{{website_url}}}",
          text: "Website",
          label: "Website",
        },
      ],
    },
    {
      name: "Special Fields",
      items: [
        // TODO: reply data and action links
        {
          value:
            '<a href="{{{reply_data_link}}}&amp;v=v2&amp;d=yes" target="_blank">Reply Link with Existing Data</a>',
          text: "Reply Link with Existing Data",
          label: "Reply Link with Existing Data",
        },
        {
          value:
            '<a href="{{{reply_data_link}}}&amp;v=v2&amp;d=no" target="_blank">Reply Link without Existing Data</a>',
          text: "Reply Link without Existing Data",
          label: "Reply Link without Existing Data",
        },
        {
          value:
            '<a href="{{{action_link}}}?action=confirm-address" target="_blank">Action Data Link</a>',
          text: "Action Data Link",
          label: "Action Data Link",
        },
        {
          value:
            '<a href="{{{personal_guide_link}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">Open My Guide</a>',
          text: "Personal Guide - Small Button link",
          label: "Personal Guide - Small Button link",
        },
        {
          value:
            '<a href="{{{personal_guide_link}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">Open My Guide</a>',
          text: "Personal Guide - Large Button link",
          label: "Personal Guide - Large Button link",
        },
        {
          value:
            '<a href="{{{personal_guide_link}}}" target="_blank">Open My Guide</a>',
          text: "Personal Guide - Simple link",
          label: "Personal Guide - Simple link",
        },
        {
          value:
            '<a href="{{{personal_events_link}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">View Events</a>',
          text: "Personal Events - Small Button link",
          label: "Personal Events - Small Button link",
        },
        {
          value:
            '<a href="{{{personal_events_link}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">View Events</a>',
          text: "Personal Events - Large Button link",
          label: "Personal Events - Large Button link",
        },
        {
          value:
            '<a href="{{{personal_events_link}}}" target="_blank">View Events</a>',
          text: "Personal Events - Simple link",
          label: "Personal Events - Simple link",
        },
        {
          value:
            '<a href="{{{convert_to_lead}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">I\'m Interested</a>',
          text: "Exhibitor - Small Button link",
          label: "Exhibitor - Small Button link",
        },
        {
          value:
            '<a href="{{{convert_to_lead}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">I\'m Interested</a>',
          text: "Exhibitor - Large Button link",
          label: "Exhibitor - Large Button link",
        },
        {
          value:
            '<a href="{{{convert_to_lead}}}" target="_blank">I\'m Interested</a>',
          text: "Exhibitor - Simple link",
          label: "Exhibitor - Simple link",
        },
        {
          value: `<table cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse" align="left">
                      <tbody>
                          <tr>
                              <td align="center" valign="top" bgcolor="#e50914" style="background-color:#e50914;background:-moz-linear-gradient(top,#e50914 5%,#bc0016 100%);background:-webkit-linear-gradient(top,#e50914 5%,#bc0016 100%);background:-o-linear-gradient(top,#e50914 5%,#bc0016 100%);background:-ms-linear-gradient(top,#e50914 5%,#bc0016 100%);background:linear-gradient(to bottom,#e50914 5%,#bc0016 100%);border-radius:2px;display:inline-block;color:#ffffff;text-decoration:none">
                                  <table cellpadding="0" cellspacing="0" border="0" width="130" style="border-collapse:collapse">
                                      <tbody>
                                          <tr>
                                              <td align="center" valign="middle" style="padding-top:11px;padding-bottom:11px;font-family:Helvetica,Arial,sans-serif;font-weight:bold;font-size:16px"> <a href="{{{convert_to_lead}}}" style="text-decoration:none;color:#ffffff;display:block" target="_blank">I'm Interested</a></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </td>
                          </tr>
                      </tbody>
                  </table>`,
          text: "Exhibitor - Squared Button",
          label: "Exhibitor - Squared Button",
        },
        {
          value:
            '<a href="{{{unsubscribe_link}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">Unsubscribe</a>',
          text: "Unsubscribe - Small Button link",
          label: "Unsubscribe - Small Button link",
        },
        {
          value:
            '<a href="{{{unsubscribe_link}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">Unsubscribe</a>',
          text: "Unsubscribe - Large Button link",
          label: "Unsubscribe - Large Button link",
        },
        {
          value:
            '<a href="{{{unsubscribe_link}}}" target="_blank">Unsubscribe</a>',
          text: "Unsubscribe - Simple link",
          label: "Unsubscribe - Simple link",
        },
        {
          value:
            '<a href="{{{individual_interested}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">I\'m Interested</a>',
          text: "Individual - I'm Interested - Small Button link",
          label: "Individual - I'm Interested - Small Button link",
        },
        {
          value:
            '<a href="{{{individual_interested}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">I\'m Interested</a>',
          text: "Individual - I'm Interested - Large Button link",
          label: "Individual - I'm Interested - Large Button link",
        },
        {
          value:
            '<a href="{{{individual_interested}}}" target="_blank">I\'m Interested</a>',
          text: "Individual - I'm Interested - Simple link",
          label: "Individual - I'm Interested - Simple link",
        },
        {
          value:
            '<a href="{{{individual_not_interested}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">I\'m not Interested</a>',
          text: "Individual - I'm not Interested - Small Button link",
          label: "Individual - I'm not Interested - Small Button link",
        },
        {
          value:
            '<a href="{{{individual_not_interested}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">I\'m not Interested</a>',
          text: "Individual - I'm not Interested - Large Button link",
          label: "Individual - I'm not Interested - Large Button link",
        },
        {
          value:
            '<a href="{{{individual_not_interested}}}" target="_blank">I\'m not Interested</a>',
          text: "Individual - I'm not Interested - Simple link",
          label: "Individual - I'm not Interested - Simple link",
        },
        {
          value:
            '<a href="{{{download_top_prospects_report}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">Download top prospects report</a>',
          text: "Download top prospects report - Small Button link",
          label: "Download top prospects report - Small Button link",
        },
        {
          value:
            '<a href="{{{download_top_prospects_report}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">Download top prospects report</a>',
          text: "Download top prospects report - Large Button link",
          label: "Download top prospects report - Large Button link",
        },
        {
          value:
            '<a href="{{{download_top_prospects_report}}}" target="_blank">Download top prospects report</a>',
          text: "Download top prospects report - Simple link",
          label: "Download top prospects report - Simple link",
        },
        {
          value:
            '<a href="{{{privacy_consent_yes_link}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">Consent</a>',
          text: "Consent To Privacy - Small Button link",
          label: "Consent To Privacy - Small Button link",
        },
        {
          value:
            '<a href="{{{privacy_consent_yes_link}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">Consent</a>',
          text: "Consent To Privacy - Large Button link",
          label: "Consent To Privacy - Large Button link",
        },
        {
          value:
            '<a href="{{{privacy_consent_yes_link}}}" target="_blank">Consent</a>',
          text: "Consent To Privacy - Simple link",
          label: "Consent To Privacy - Simple link",
        },
        {
          value:
            '<a href="{{{registration_link}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">Register</a>',
          text: "Show Registration - Small Button link",
          label: "Show Registration - Small Button link",
        },
        {
          value:
            '<a href="{{{registration_link}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">Register</a>',
          text: "Show Registration - Large Button link",
          label: "Show Registration - Large Button link",
        },
        {
          value:
            '<a href="{{{registration_link}}}" target="_blank">Register</a>',
          text: "Show Registration - Simple link",
          label: "Show Registration - Simple link",
        },
        {
          value:
            '<a href="{{{SocialMedia_Url1}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">SocialMedia_Url1</a>',
          text: "SocialMedia_Url1 - Small Button link",
          label: "SocialMedia_Url1 - Small Button link",
        },
        {
          value:
            '<a href="{{{SocialMedia_Url1}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">SocialMedia_Url1</a>',
          text: "SocialMedia_Url1 - Large Button link",
          label: "SocialMedia_Url1 - Large Button link",
        },
        {
          value:
            '<a href="{{{SocialMedia_Url1}}}" target="_blank">SocialMedia_Url1</a>',
          text: "SocialMedia_Url1 - Simple link",
          label: "SocialMedia_Url1 - Simple link",
        },
        {
          value:
            '<a href="{{{SocialMedia_Url2}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:5px 10px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">SocialMedia_Url2</a>',
          text: "SocialMedia_Url2 - Small Button link",
          label: "SocialMedia_Url2 - Small Button link",
        },
        {
          value:
            '<a href="{{{SocialMedia_Url2}}}" style="display:inline-block;font-size:15px;color:#ffffff;padding:10px 15px;text-decoration:none;background-color:#1376d7;border-radius:3px;" target="_blank">SocialMedia_Url2</a>',
          text: "SocialMedia_Url2 - Large Button link",
          label: "SocialMedia_Url2 - Large Button link",
        },
        {
          value:
            '<a href="{{{SocialMedia_Url2}}}" target="_blank">SocialMedia_Url2</a>',
          text: "SocialMedia_Url2 - Simple link",
          label: "SocialMedia_Url2 - Simple link",
        },
      ],
    },
  ],
};
