import React from 'react';
import './App.css';
import MainPage from './app/containers/MainPage'



function App() {
  return (
    <MainPage />)
}

export default App;
