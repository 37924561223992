import React, { useRef, useEffect } from 'react'
import { GlobalStyle, EmailEditorContainer } from '../common/StyledComponents'

import Editor from './editor'
import Header from './EmailEditorHeader'
import FileManager from './editor_modals/FileManager'
import SaveBlockModal from './editor_modals/SaveBlockModal'
import empty_template from '../../../helpers/empty_template.json'
import constants from '../../../helpers/constants'

export default ({
  template,
  clients,
  eid,
  selectedRadio,
  setSelectedRadio,
  selectedClient,
  setSelectedClient,
  name,
  setName,
  onSaveAndClose,
  onSave,
  onAutoSave,
  onTestSend,
  onOpenFileManager,
  onBlockSave,
  onBlockEdit,
  onBlockRemove,
  savedBlocks,
  showFileManager,
  toggleFileManager,
  showSaveBlockModal,
  saveBlock,
  toggleSaveBlockModal
}) => {
  const editor = useRef(null)

  const initTemplate = () => {
    if (template) editor.current.loadDesign(JSON.stringify(template.content_json))
    else editor.current.loadDesign(JSON.stringify(empty_template));
  }

  useEffect(() => {
    editor.current.setSavedBlocks(savedBlocks)
  }, [savedBlocks])

  const saveDesign = () => {
    editor.current.saveDesign(design => {
      console.log('saveDesign', design)
      // alert("Design JSON has been logged in your developer console.")
    })
  }

  const selectImage = (name) => {
    editor.current.chooseFile(`https://s3.amazonaws.com/exposync-uploads/${name}`)
    toggleFileManager()
  }

  return (
    <React.Fragment>
      <GlobalStyle />
      <EmailEditorContainer>
        <Header
          clients={clients}
          selectedRadio={selectedRadio}
          setSelectedRadio={setSelectedRadio}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          name={name}
          setName={setName}
          disabled={template && template.id} />
        <Editor
          ref={editor}
          onLoad={initTemplate}
          saveDesign={saveDesign}
          onSaveAndClose={onSaveAndClose}
          onSave={onSave}
          onAutoSave={onAutoSave}
          onTestSend={onTestSend}
          onOpenFileManager={onOpenFileManager}
          onBlockSave={onBlockSave}
          onBlockEdit={onBlockEdit}
          onBlockRemove={onBlockRemove}
          // templateId={1}
          authorize={{
            apiKey: "IK7FO7oDHOkNaQ3JXiHSuRSvkWyKRk6yWHLuPw3L2OB5JaBezIXCCvkNAtPy",
            userId: eid,
          }}
          title={name} // Title shown on the top of the main menu
          removeTopBar={false} // Hides the top bar of the email editor
          topBarOptions={[
            "undoRedo",
            "changePreview",
            "previewSize",
            // "previewTestMail"
          ]} // Displays given elements in top bar 
          mainMenuAlign="right" // Main menu on right or left side
          hideSettingsTab={false} // Hides settings tab
          disableAlerts={false} // Disables alerts
          light={true} // set the editor theme to be light
          customFileManager={true} // Sets the build in file manager to be disabled and change to call the callbacks provided below
          mergeTags={constants.mergeTags}
          googleFonts={[  // List of google fonts to load
            'Roboto',
            'K2D',
            'Mali'
          ]}
          fonts={constants.fonts}
          tinyConfig={{
            menubar: 'edit insert format table',
            plugins: ["advlist link image textcolor", "media", "colorpicker", "code", "preview", "table", "paste", "lists"],
            toolbar: [" styleselect | undo | redo | removeformat | table | fontselect | fontsizeselect | fields | custom_buttons | bold | italic | underline | removeformat | link image | forecolor | backgroundcolor  | code | preview", " alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent"],
            style_formats: [
              {
                  title: 'Image Left',
                  selector: 'img',
                  styles: {
                      'float': 'left',
                      'margin': '0 10px 0 10px'
                  }
              },
              {
                  title: 'Image Right',
                  selector: 'img',
                  styles: {
                      'float': 'right',
                      'margin': '0 0 10px 10px'
                  }
              },
              {
                  title: 'Row Spacing',
                  items: [
                      {
                          title: '1.1',
                          selector: 'p,ul',
                          styles: {
                              'line-height': '1.1'
                          }
                      },
                      {
                          title: '1.2',
                          selector: 'p,ul',
                          styles: {
                              'line-height': '1.2'
                          }
                      },
                      {
                          title: '1.3',
                          selector: 'p,ul',
                          styles: {
                              'line-height': '1.3'
                          }
                      },
                      {
                          title: '1.4',
                          selector: 'p,ul',
                          styles: {
                              'line-height': '1.4'
                          }
                      },
                      {
                          title: '1.5',
                          selector: 'p,ul',
                          styles: {
                              'line-height': '1.5'
                          }
                      },
                      {
                          title: '1.6',
                          selector: 'p,ul',
                          styles: {
                              'line-height': '1.6'
                          }
                      },
                      {
                          title: '1.7',
                          selector: 'p,ul',
                          styles: {
                              'line-height': '1.7'
                          }
                      },
                      {
                          title: '1.8',
                          selector: 'p,ul',
                          styles: {
                              'line-height': '1.8'
                          }
                      },
                      {
                          title: '1.9',
                          selector: 'p,ul',
                          styles: {
                              'line-height': '1.9'
                          }
                      },
                      {
                          title: '2.0',
                          selector: 'p,ul',
                          styles: {
                              'line-height': '2.0'
                          }
                      },
                  ]
              },
              {
                  title: 'Padding',
                  items: [{
                      title: 'All',
                      items: [
                          {
                              title: '0px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '0px'
                              }
                          },
                          {
                              title: '5px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '5px'
                              }
                          },
                          {
                              title: '10px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '10px'
                              }
                          },
                          {
                              title: '15px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '15px'
                              }
                          },
                          {
                              title: '20px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '20px'
                              }
                          },
                          {
                              title: '25px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '25px'
                              }
                          },
                          {
                              title: '30px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '30px'
                              }
                          },
                          {
                              title: '35px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '35px'
                              }
                          },
                          {
                              title: '40px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '40px'
                              }
                          },
                          {
                              title: '45px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '45px'
                              }
                          },
                          {
                              title: '50px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '50px'
                              }
                          }
                      ]
                  },
                  {
                      title: 'Left',
                      items: [
                          {
                              title: '0px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '0px'
                              }
                          },
                          {
                              title: '5px',
                              selector: 'td,p',
                              styles: {
                                  'padding-left': '5px'
                              }
                          },
                          {
                              title: '10px',
                              selector: 'td,p',
                              styles: {
                                  'padding-left': '10px'
                              }
                          },
                          {
                              title: '15px',
                              selector: 'td,p',
                              styles: {
                                  'padding-left': '15px'
                              }
                          },
                          {
                              title: '20px',
                              selector: 'td,p',
                              styles: {
                                  'padding-left': '20px'
                              }
                          },
                          {
                              title: '25px',
                              selector: 'td,p',
                              styles: {
                                  'padding-left': '25px'
                              }
                          },
                          {
                              title: '30px',
                              selector: 'td,p',
                              styles: {
                                  'padding-left': '30px'
                              }
                          },
                          {
                              title: '35px',
                              selector: 'td,p',
                              styles: {
                                  'padding-left': '35px'
                              }
                          },
                          {
                              title: '40px',
                              selector: 'td,p',
                              styles: {
                                  'padding-left': '40px'
                              }
                          },
                          {
                              title: '45px',
                              selector: 'td,p',
                              styles: {
                                  'padding-left': '45px'
                              }
                          },
                          {
                              title: '50px',
                              selector: 'td,p',
                              styles: {
                                  'padding-left': '50px'
                              }
                          }
                      ]
                  },
                  {
                      title: 'Right',
                      items: [
                          {
                              title: '0px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '0px'
                              }
                          },
                          {
                              title: '5px',
                              selector: 'td,p',
                              styles: {
                                  'padding-right': '5px'
                              }
                          },
                          {
                              title: '10px',
                              selector: 'td,p',
                              styles: {
                                  'padding-right': '10px'
                              }
                          },
                          {
                              title: '15px',
                              selector: 'td,p',
                              styles: {
                                  'padding-right': '15px'
                              }
                          },
                          {
                              title: '20px',
                              selector: 'td,p',
                              styles: {
                                  'padding-right': '20px'
                              }
                          },
                          {
                              title: '25px',
                              selector: 'td,p',
                              styles: {
                                  'padding-right': '25px'
                              }
                          },
                          {
                              title: '30px',
                              selector: 'td,p',
                              styles: {
                                  'padding-right': '30px'
                              }
                          },
                          {
                              title: '35px',
                              selector: 'td,p',
                              styles: {
                                  'padding-right': '35px'
                              }
                          },
                          {
                              title: '40px',
                              selector: 'td,p',
                              styles: {
                                  'padding-right': '40px'
                              }
                          },
                          {
                              title: '45px',
                              selector: 'td,p',
                              styles: {
                                  'padding-right': '45px'
                              }
                          },
                          {
                              title: '50px',
                              selector: 'td,p',
                              styles: {
                                  'padding-right': '50px'
                              }
                          }]
                  },
                  {
                      title: 'Top',
                      items: [
                          {
                              title: '0px',
                              selector: 'td,p',
                              styles: {
                                  'padding': '0px'
                              }
                          },
                          {
                              title: '5px',
                              selector: 'td,p',
                              styles: {
                                  'padding-top': '5px'
                              }
                          },
                          {
                              title: '10px',
                              selector: 'td,p',
                              styles: {
                                  'padding-top': '10px'
                              }
                          },
                          {
                              title: '15px',
                              selector: 'td,p',
                              styles: {
                                  'padding-top': '15px'
                              }
                          },
                          {
                              title: '20px',
                              selector: 'td,p',
                              styles: {
                                  'padding-top': '20px'
                              }
                          },
                          {
                              title: '25px',
                              selector: 'td,p',
                              styles: {
                                  'padding-top': '25px'
                              }
                          },
                          {
                              title: '30px',
                              selector: 'td,p',
                              styles: {
                                  'padding-top': '30px'
                              }
                          },
                          {
                              title: '35px',
                              selector: 'td,p',
                              styles: {
                                  'padding-top': '35px'
                              }
                          },
                          {
                              title: '40px',
                              selector: 'td,p',
                              styles: {
                                  'padding-top': '40px'
                              }
                          },
                          {
                              title: '45px',
                              selector: 'td,p',
                              styles: {
                                  'padding-top': '45px'
                              }
                          },
                          {
                              title: '50px',
                              selector: 'td,p',
                              styles: {
                                  'padding-top': '50px'
                              }
                          }]
                  },
                  {
                      title: 'Bottom',
                      items: [
                          {
                              title: '5px',
                              selector: 'td,p',
                              styles: {
                                  'padding-bottom': '5px'
                              }
                          },
                          {
                              title: '10px',
                              selector: 'td,p',
                              styles: {
                                  'padding-bottom': '10px'
                              }
                          },
                          {
                              title: '15px',
                              selector: 'td,p',
                              styles: {
                                  'padding-bottom': '15px'
                              }
                          },
                          {
                              title: '20px',
                              selector: 'td,p',
                              styles: {
                                  'padding-bottom': '20px'
                              }
                          },
                          {
                              title: '25px',
                              selector: 'td,p',
                              styles: {
                                  'padding-bottom': '25px'
                              }
                          },
                          {
                              title: '30px',
                              selector: 'td,p',
                              styles: {
                                  'padding-bottom': '30px'
                              }
                          },
                          {
                              title: '35px',
                              selector: 'td,p',
                              styles: {
                                  'padding-bottom': '35px'
                              }
                          },
                          {
                              title: '40px',
                              selector: 'td,p',
                              styles: {
                                  'padding-bottom': '40px'
                              }
                          },
                          {
                              title: '45px',
                              selector: 'td,p',
                              styles: {
                                  'padding-bottom': '45px'
                              }
                          },
                          {
                              title: '50px',
                              selector: 'td,p',
                              styles: {
                                  'padding-bottom': '50px'
                              }
                          }
                      ]
                  }]
              }
          ],
          font_formats: 'Century Gothic=Century Gothic,CenturyGothic,AppleGothic,sans-serif;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;',
            schema: 'html5'
          }}
          savedBlocks={savedBlocks}
          premadeBlocks={{}}
          // premadeBlocks={{
          //   'headers': [
          //     {
          //       'img': 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png', // Image url
          //       'definition': [{ "tagName": "mj-section", "attributes": { "full-width": false, "padding": "9px 0px 9px 0px", "background-color": "#000000" }, "type": null, "children": [{ "tagName": "mj-column", "attributes": { "width": "33.333333%", "vertical-align": "top" }, "children": [{ "tagName": "mj-social", "attributes": { "display": "facebook:url twitter:url google:url", "padding": "10px 10px 10px 30px", "text-mode": "false", "icon-size": "25px", "base-url": "https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/", "facebook-href": "https://www.facebook.com/PROFILE", "facebook-icon-color": "none", "facebook-alt": "Sdílet", "twitter-href": "https://www.twitter.com/PROFILE", "twitter-icon-color": "none", "twitter-alt": "", "google-href": "https://plus.google.com/PROFILE", "google-icon-color": "none", "google-alt": "", "instagram-icon-color": "none", "linkedin-icon-color": "none", "align": "left", "youtube-icon-color": "none", "youtube-alt": "", "youtube-icon": "https://s3-eu-west-1.amazonaws.com/ecomail-assets/editor/social-icos/simplewhite/youtube.png", "youtube-href": "https://www.youtube.com", "containerWidth": 200 }, "uid": "H1lqIiX4lm" }], "uid": "SJ3I0XVx7" }, { "tagName": "mj-column", "attributes": { "width": "33.333333%", "vertical-align": "top" }, "children": [{ "tagName": "mj-image", "attributes": { "src": "https://storage.googleapis.com/jan50/blackberrylogo.png", "padding": "19px 10px 10px 10px", "alt": "", "href": "", "containerWidth": 200, "width": 100, "widthPercent": 50 }, "uid": "rkEyL-HeQ" }], "uid": "r1e280m4xQ" }, { "tagName": "mj-column", "attributes": { "width": "33.333333%", "vertical-align": "top" }, "children": [{ "tagName": "mj-spacer", "attributes": { "height": 15, "containerWidth": 200 }, "uid": "rJfqLiXEgm" }], "uid": "B1W380QVxX" }], "layout": 1, "backgroundColor": "", "backgroundImage": "", "paddingTop": 0, "paddingBottom": 0, "paddingLeft": 0, "paddingRight": 0, "uid": "rkqIjQNe7" } // MJML JSON
          //       ]
          //     }]
          // }}
          contentBlocks = {{
            product: {
                disabled: true,
                disabledText: 'Text In Tooltip',
                hidden: true
            },
          }}
          sendTestEmail={false} // Hides the send test email input & button if false
          api={{
            IMAGE_UPLOAD: "upload",
            // Your own endpoint for uploading images
            FOLDERS: "folders"
            // Your own endpoint for getting contents of current folder
          }}
        />
      </EmailEditorContainer>
      <FileManager show={showFileManager} toggleFileManager={toggleFileManager} eid={eid} selectImage={selectImage} selectedClient={selectedClient} />
      <SaveBlockModal show={showSaveBlockModal} saveBlock={saveBlock} toggleSaveBlockModal={toggleSaveBlockModal} />
    </React.Fragment>
  )
}

