import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from 'reactstrap';


export default ({ show, saveBlock, toggleSaveBlockModal }) => {

  const [value, setValue] = useState('')

  return (
    <Modal isOpen={show} toggle={toggleSaveBlockModal}>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="blockName">Enter block name: </Label>
            <Input type="text"
              name="block_name"
              value={value}
              onChange={(event) => setValue(event.target.value)} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => saveBlock(value)}>OK</Button>{' '}
        <Button color="secondary" onClick={toggleSaveBlockModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};