import React from 'react';
import { Input, Label, Form, FormGroup, Col, Row } from 'reactstrap';


export default ({
  clients,
  selectedRadio,
  selectedClient,
  setSelectedRadio,
  setSelectedClient,
  name,
  setName,
  disabled }) => {
  return (
    <div style={{ border: '1px solid #d5dadf', borderBottom: 'none', backgroundColor: '#f1f1f1', paddingLeft: 10, paddingTop: 10 }}>
      <Form>
        <Row form>
          <Col md={2} style={{ display: 'flex', alignItems: 'center' }}>
            <FormGroup inline>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="expo" value="expo" checked={selectedRadio === 'expo'} onChange={(event) => setSelectedRadio(event.target.value)} disabled={disabled} />{' '}
            Expo Template
          </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="client" value="client" checked={selectedRadio === 'client'} onChange={(event) => setSelectedRadio(event.target.value)} disabled={disabled} />{' '}
                 Client Template
          </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup >
              <Label for="templateName">Name</Label>
              <Input type="text"
                name="template_name"
                value={name}
                onChange={(event) => setName(event.target.value)} />
            </FormGroup>
          </Col>
          {selectedRadio === 'client' && (<Col md={4}><FormGroup>
            <Label for="clientSelect">Client</Label>
            <Input type="select" name="select" id="clientSelect" value={selectedClient} onChange={(event) => setSelectedClient(event.target.value)} disabled={disabled} >
              {clients.map(client => <option key={client.id} value={client.id}>{client.exhibitor_name}</option>)}
            </Input>
          </FormGroup></Col>)}
        </Row>
      </Form>
    </div>
  );
};