import React from 'react'

export default () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div className='fa-3x'>
        <i className="fa fa-circle-o-notch fa-spin" />
      </div>
    </div>
  )
}
