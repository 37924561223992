import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Table } from "reactstrap";
import {
  BASE_URL,
  getData,
  handleErrors,
  s3_config,
  postData,
} from "../../../../config";
import {
  FileManagerButton,
  FileManagerImg,
  FileManagerTableRow,
  FileManagerSpan,
  FileManagerThead,
} from "../../common/StyledComponents";
import {
  bytesToSize,
  formatDate,
  formatFileName,
} from "../../../../helpers/format";
import Spinner from "../../common/Spinner";
import S3 from "react-aws-s3";

export default ({
  show,
  toggleFileManager,
  eid,
  selectImage,
  selectedClient,
}) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientImages, setClientImages] = useState([]);

  const ReactS3Client = new S3(s3_config);

  const hiddenUploadImageInput = useRef();

  const sortImagesByDate = (a, b) => {
    if (new Date(a.date).getTime() > new Date(b.date).getTime()) return -1;
    else return 1;
  };

  const uploadFile = (event) => {
    const file = event.target.files[0];
    if (file !== undefined && file !== null) {
      if (file.size > 517200) {
        console.log("exceeded 500 KB"); // TODO: show error
        return;
      } else {
        const filename = formatFileName(file.name);
        setLoading(true);
        ReactS3Client.uploadFile(file, filename)
          .then((data) => {
            toggleFileManager();
            selectImage(data.key);
            addImageToClient(data.key);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleUploadClick = () => {
    hiddenUploadImageInput.current.click();
  };

  const fetchExpoImages = useCallback(async () => {
    setLoading(true);
    await fetch(`${BASE_URL}/api/email_editor/images/${eid}`, getData)
      .then(handleErrors)
      .then((response) => response.json())
      .then((ret) => {
        setImages(ret);
      })
      .catch(console.log);
    setLoading(false);
  }, [eid]);

  const fetchClientImages = useCallback(async () => {
    if (selectedClient) {
      setLoading(true);
      await fetch(
        `${BASE_URL}/api/email_editor/images/exhibitor/${selectedClient}`,
        getData
      )
        .then(handleErrors)
        .then((response) => response.json())
        .then((ret) => {
          setClientImages(ret);
        })
        .catch(console.log);
      setLoading(false);
    }
  }, [selectedClient]);

  const addImageToClient = async (key) => {
    await fetch(`${BASE_URL}/api/email_editor/images/exhibitor/${selectedClient}`, postData({ key }))
    .then(handleErrors)
    .then(() => {
      fetchExpoImages();
      fetchClientImages();
    }).catch((err) => {})
  }
  const addImageToExpo = async (key) => {
    await fetch(`${BASE_URL}/api/email_editor/images/${eid}`, postData({ key }))
      .then(handleErrors)
      .then(() => {
        fetchExpoImages();
        fetchClientImages();
      })
      .catch((err) => {});
  };

  const removeImage = async (event, image) => {
    event.stopPropagation()
    const data = {
      image: image.name,
      expo_id: eid,
    };
    if (selectedClient) data.company_id = selectedClient;
    await fetch(
      `${BASE_URL}/api/email_editor/images/${eid}/remove`,
      postData(data)
    )
      .then(handleErrors)
      .then(() => {
        fetchExpoImages();
        fetchClientImages();
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchExpoImages();
  }, [fetchExpoImages]);

  useEffect(() => {
    fetchClientImages();
  }, [fetchClientImages]);

  return (
    <Modal
      isOpen={show}
      toggle={toggleFileManager}
      className="file-manager-modal"
    >
      <ModalHeader toggle={toggleFileManager}>
        <Row>
          <Col>File manager</Col>
        </Row>
        <Row>
          <Col>
            <FileManagerButton
              onClick={() => {
                fetchExpoImages();
                fetchClientImages();
              }}
              size="sm"
              outline
              color="info"
            >
              <i className="fa fa-refresh mx-1" aria-hidden="true" />
            </FileManagerButton>{" "}
            {/* <FileManagerButton size="sm" outline color="success"><i className="fa fa-folder mx-1" aria-hidden="true" /></FileManagerButton>{' '} */}
            <FileManagerButton
              onClick={handleUploadClick}
              size="sm"
              color="primary"
            >
              <span>
                Upload{" "}
                <i className="fa fa-cloud-upload mx-1" aria-hidden="true"></i>
              </span>
            </FileManagerButton>{" "}
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Spinner />
        ) : (
          <Row>
            <Col md="12" className="file-manager-modal-body">
              {images.length ? (
                <Table hover>
                  <FileManagerThead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Date</th>
                      <th>Size</th>
                      <th>Type</th>
                      <th></th>
                    </tr>
                  </FileManagerThead>
                  <tbody>
                    {[...images, ...clientImages]
                      .sort(sortImagesByDate)
                      .map((img) => (
                        <FileManagerTableRow
                          key={img.name}
                          onClick={() => selectImage(img.name)}
                        >
                          <th>
                            <FileManagerImg
                              src={`${s3_config.s3Url}/${img.name}`}
                            />
                          </th>
                          <th>
                            <FileManagerSpan>{img.name}</FileManagerSpan>
                          </th>
                          <th>
                            <FileManagerSpan>
                              {formatDate(img.date)}
                            </FileManagerSpan>
                          </th>
                          <th>
                            <FileManagerSpan>
                              {bytesToSize(img.size)}
                            </FileManagerSpan>
                          </th>
                          <th>
                            <FileManagerSpan>{img.type}</FileManagerSpan>
                          </th>
                          <th>
                            <FileManagerButton
                              onClick={(e) => removeImage(e, img)}
                              size="sm"
                              outline
                              color="info"
                            >
                              <i
                                className="fa fa-trash mx-1"
                                aria-hidden="true"
                              />
                            </FileManagerButton>{" "}
                          </th>
                        </FileManagerTableRow>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        )}
        <input
          ref={hiddenUploadImageInput}
          type="file"
          accept="image/png, image/jpeg"
          onChange={uploadFile}
          style={{ display: "none" }}
        />
      </ModalBody>
    </Modal>
  );
};
